/**
 * Toolkit styles
 */

html {
    font-size: 62.5%;
}

@import 'partials/mixins';
@import 'partials/variables';
@import 'partials/base';
@import 'partials/grid';
@import 'partials/borders';
@import 'partials/typography';
@import 'partials/buttons';
@import 'partials/forms';
@import 'partials/lists';
@import 'partials/media';
@import 'partials/animations';
@import 'partials/event';
@import 'partials/header';
