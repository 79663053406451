/**
 * Base styles
 */

// Not all elements inherit the following styles by default (eg textarea), so enforce it here.
// https://www.smashingmagazine.com/2016/11/css-inheritance-cascade-global-scope-new-old-worst-best-friends/
* {
    font-family: inherit;
    line-height: inherit;
    color: inherit;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.5;
    background: $white;
}

main {
    padding: 0 0 50px;
}

// Semantic clearfix
.group {
    position: relative;
    &:after {
        clear: both;
    }
    &:before,
    &:after {
        display: table;
        content: ' ';
    }
}

.collapse {
    display: none;
}

.hidden {
    display: none !important;
}

.loading {
    text-align: center;
}
