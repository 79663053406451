/**
 * Form styles
 */

.disabled {
    color: $grey;
}

fieldset {
    margin: 1em 0;
}

input[type='text'],
input[type='email'],
input[type='tel'],
input[type='number'],
select,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    padding: 12px 15px;
    @include font-size(16);
    background-color: $light-grey;
    border: 1px solid $grey;
    border-radius: 0;
    margin: 0.5em 0 0.75em;
}

input[type='text'],
input[type='email'],
input[type='tel'],
input[type='number'],
select {
    height: 50px;
}

.float-label {
    position: relative;
    display: flex;
    label {
        padding-left: 15px;
        position: absolute;
        transform: translate(0, 23px) scale(1);
        transform-origin: top left;
        transition: all 0.2s ease-out;
        vertical-align: middle;
        font-weight: normal;
        width: 100%;
        // the label initially covers the select input, so the following allows one
        // click rather than two to display the select options
        pointer-events: none;
    }
}

.float-label:focus-within label,
.float-label .active-label
 {
    transform: translate(7px, 15px) scale(0.65);
}

.float-label:focus-within input,
.float-label .active-input {
    padding-top: 24px;
}

select {
    /*
    Bootstrap approach, using custom inline SVG background image: https://v4-alpha.getbootstrap.com/components/forms/#select-menu
    Not using a pseudo element here (unlike for buttons) as the select element does not allow them and therefore an additional wrapper element would be necessary: http://stackoverflow.com/questions/21103542/pseudo-elements-and-select-tag)
    SVG content must be url-encoded (spaces allowed though): https://codepen.io/tigt/post/optimizing-svgs-in-data-uris
    SVG polygons can be converted to a path by appending 'M' and prepending 'z': http://stackoverflow.com/questions/13679495/examples-of-polygons-drawn-by-path-vs-polygon-in-svg
    */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 21'%3E%3Cpath d='M20 21 0 1 1 0 20 19 39 0 40 1 20 21z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 20px 20px;
    padding-right: 45px;
}

.float-label select.active-input {
    padding-top: 18px;
    padding-bottom: 5px;
}

select::-ms-expand {
    display: none;
}

input[type='text']:focus,
input[type='email']:focus,
input[type='tel']:focus,
input[type='number']:focus,
select:focus,
textarea:focus {
    border: 1px solid $white-on-green;
    outline: none;
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $black;
}

label:not(.col):not(.control),
.radio .label {
    color: $dark-grey;
    clear: both;
    display: block;
    .error {
        font-weight: 800;
    }
}

/* Custom-styled checkboxes and radio buttons
   https://css-tricks.com/custom-styling-form-inputs-with-modern-css-features/
*/
input[type='checkbox'],
input[type='radio'] {
    margin: 0 10px 0 0;
}

.control {
    @include font-size(16);
    position: relative;
    display: inline-block;
    display: inline-flex;
    align-items: center;
    clear: both;
    margin-bottom: 15px;
    margin-right: 15px;
    padding-left: 0;
    cursor: pointer;
    min-height: 40px;
}

.control--small {
    display: flex;
    @include font-size(14);
    margin-bottom: 15px;
    cursor: pointer;
    min-height: 20px;
}

.control span {
  display: inline-block;
}

.control__indicator {
    position: relative;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
}

.control__indicator--small {
    width: 20px;
    height: 20px;
}

.control--radio .control__indicator {
    border-radius: 50%;
}

/* Focus state */
.control input:focus {
    background: $light-grey;
    border: 1px solid $white-on-green;
    outline: none;
}

/* Hover state whilst checked */
@supports(-webkit-appearance: none) or (-moz-appearance: none) {
    input[type='checkbox'],
    input[type='radio'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: $light-grey;
        border: 1px solid $grey;
    }

    /* Checked state */
    input[type='checkbox']:checked,
    input[type='radio']:checked {
        background: $white-on-green;
        border: 1px solid $white-on-green;
    }

    /* Focused state */
    input[type='checkbox']:focus {
        border: 1px solid $white-on-green;
        outline: none;
    }

    /* Unchecked state */
    input[type='checkbox'] {
        background: $light-grey;
    }

    /* Disabled state */
    input[type='checkbox']:disabled,
    input[type='radio']:disabled {
        pointer-events: none;
        opacity: .6;
        background: $grey;
    }

    /* Disabled checked state */
    input[type='checkbox']:disabled:checked,
    input[type='radio']:disabled:checked  {
        pointer-events: none;
        opacity: .4;
        background: $white-on-green;
    }

    /* Check mark */
    .control__indicator:after {
        position: absolute;
        display: none;
        content: '';
    }

    /* Show check mark */
    input[type='checkbox']:checked::after,
    input[type='radio']:checked::after {
        display: block;
    }

    /* Checkbox tick */
    input[type='checkbox']::after {
        top: 2px;
        left: 12px;
        width: 9px;
        height: 24px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        border: solid black;
        border-width: 0 4px 4px 0;
    }

    .control--checkbox .control__indicator--small::after {
        top: 1px;
        left: 6px;
        width: 4px;
        height: 12px;
        border-width: 0 2px 2px 0;
    }

    /* Radio button inner circle */
    input[type='radio']::after {
        top: 10px;
        left: 10px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: black;
    }

    input[type='radio']:checked + span,
    input[type='checkbox']:checked + span {
        font-weight: 700;;
    }

    .control--radio .control__indicator--small::after {
        top: 5px;
        left: 5px;
        width: 8px;
        height: 8px;
    }

}

/* Yes/No style radio buttons */
.yes-no {
    display: inline-flex;
    width: 130px;
    padding: 10px;
    @include mq('desktop') {
        width: 150px;
    }
    min-height: 48px;
    margin-right: 25px;
    @include font-size(18);
    .control__indicator {
        display: none;
    }
}

/* Success styles */
input[type='text'].success,
input[type='email'].success,
input[type='tel'].success,
input[type='number'].success,
select.success,
textarea.success,
.control__indicator.success {
    border: 2px solid $white-on-green;
}

.input-wrapper label.success {
    font-weight: 700;
    color: $white-on-green;
}

/* Error styles */
input[type='text'].error,
input[type='email'].error,
input[type='tel'].error,
input[type='number'].error,
select.error,
textarea.error,
.control__indicator.error {
    border: 2px solid #e36060;
}

.input-wrapper label.error,
.input-wrapper input.error::placeholder {
    font-weight: 700;
    color: #e36060;
}

.error-message {
    display: block;
    margin: -5px 0 1em;
    @include font-size(14);
    font-style: italic;
    font-weight: 400;
    color: #e36060;
}

.show {
    display: block;
}

.hide {
    display: none;
}

.inactive {
    opacity: 0;
    height: 0;
    overflow: hidden;
}

.active {
    opacity: 1;
    height: auto;
    transition: opacity 1000ms ease-in;
}

fieldset {
    border: 0;
    padding: 0;
    margin: 1em 0;
    .question {
        h2 {
            display: inline-block;
            padding: 0;
            margin: 0 0 0.5em;
            line-height: 1.4em;
            &.completed {
                color: $dark-grey;
            }
        }
        span:nth-of-type(2) label {
            margin-right: 0;
        }
        .span_2_of_12 {
            float: right;
        }
    }
}

.answer {
    text-transform: uppercase;
    text-align: center;
    padding: 5px;
    background-color: $dark-grey;
    color: $white;
    margin-bottom: 0.5em;
    span {
        font-size: 1em;
        font-family: $gotham;
        font-weight: 300;
        letter-spacing: -0.05em;
        &:last-child {
            padding: 0 0 0 10px;
        }
        @include mq('mobile') {
            &:first-child {
                font-size: .5em;
                line-height: 2em;
            }
            &:last-child {
                padding: 0;
            }
            font-size: 1.2em;
            display: block;
            line-height: 1em
        }
    }
    &.failed {
        background-color: $black;
    }
}
