// Import Anthony Nolan Styleguide
@import '../styleguide/toolkit.scss';

html {
  position: relative;
  min-height: 100%;
}

body {
  background: #f7f6f0;
  margin: 0 0 35px;
  /* bottom = footer height */
}

p:first-of-type {
  margin-top: 1em;
}

a {
  color: $green-on-white;
}

footer a {
  color: $mid-grey;
}

.content {
  position: relative;
}

.section {
  @include clearfix();
}

.btn:focus {
  outline: none;
}

.environment-indicator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;

  h1 a {
    color: unset;
  }

  .btn {
    height: 50px;
    font-size: 1.4rem;
  }
}

.environment-indicator__header {
  margin: 0;
}

.btn--link {
  display: inline;
  padding: 0;
  background: none;
  text-decoration: underline;
  text-transform: none;
  font-weight: normal;
  font-size: 1.6rem;
  min-height: 0;
}

.loader.exsmall {
  position: absolute;
  margin: 35px 0 0 -23px;
  border-width: 8px;
  top: 0;
}

.loader--btn {
  position: static;
  float: right;
  width: 10px;
  height: 10px;
  border-width: 3px;
  margin: 5px 0 0 10px;
  border-color: $black;
  border-top-color: $green;
}

.loader--relative {
  position: relative;
  margin-top: 0;
}

.btn--small .loader--btn {
  border-width: 2px;
  margin: 3px 0 0 10px;
}

.input-wrapper {
  position: relative;
}

.loader--input {
  position: absolute;
  top: auto;
  left: auto;
  bottom: 28px;
  right: 10px;
  width: 10px;
  height: 10px;
  border-width: 3px;
  margin: 5px 0 0 10px;
}

.indicator-row {
  button {
    width: 100%;
    padding: 0;
    height: 10px;
    min-height: 0;
    border-bottom: none;
  }
}

.environment-link {
  margin-bottom: 4px;
}

// flexbox layouts
.flex-wrapper {
  @include mq('mobile') {
    display: flex;
  }
}

.flex-item + .flex-item {
  @include mq('mobile') {
    flex-grow: 1;
    margin-left: 10px;
  }
}

// Webforms-specific styles

.button-actions {
  display: flex;
}

input:disabled {
  color: $grey;
}

input.error:disabled {
  color: $mid-grey;
}

button:disabled {
  opacity: 0.25;
}

.btn--invalid {
  opacity: 0.5;
  filter: grayscale(100%);
}

// Remove arrows in date fields
input.date-field::-webkit-outer-spin-button,
input.date-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

// Remove arrows in phone number fields
.phoneNumber input.number::-webkit-outer-spin-button,
.phoneNumber input.number::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.error-message {
  clear: both;
  font-style: normal;
  margin: 0 0 1em;

  a {
    color: inherit;
  }

  p {
    @include font-size(14);
  }
}

.form-header,
.payment-details {
  h2 {
    @include font-size(24);
    font-weight: 700;
    color: $white-on-green;
  }
}

fieldset h2 {
  margin-bottom: 0.5em;
}

fieldset h3 {
  margin: 1em 0 0.5em;
}

fieldset.content > div > h2,
fieldset.personalDetails > div > h2 {
  @include font-size(24);
  font-weight: 700;
  text-transform: uppercase;
  color: $white-on-green;
  margin-bottom: 1em;
}

.description {
  margin: 0.5em 0;
}

// Pre Qualifiers
.preQualifiers {
  margin-bottom: 50px;

  h3 {
    margin-top: 0.5em;
    @include font-size(24);
    font-weight: 300;
  }

  .error-box {
    background: none;
    border: 0;
    padding: 0;

    .error-message {
      display: none;
    }
  }
}

// Personal Details
.personalDetails {
  .title {
    min-width: 100px;
  }

  .middleName {
    min-width: 250px;
  }
}

// Height & Weight
.heightWeight .control--small {
  display: inline-flex;
}

.height-indicator,
.weight-indicator {
  font-weight: 700;

  @include mq('mobile') {
    line-height: 65px;
  }
}

.bmi-fail-wrapper,
.weight-fail-wrapper {
  display: none;
}

// Date of birth
.dateOfBirth {
  .label {
    color: $black;
    font-weight: bold;
  }
}

.age-indicator {
  margin-bottom: 1em;

  @include mq('mobile') {
    margin-top: 20px;
  }
}

// Gender
.gender {
  margin: 2em 0 1em;
}

// Occupation
.military {
  margin-bottom: 1em;
}

// Ethnicity
.ethnicity {
  .label {
    color: $black;
    font-weight: bold;
  }

  .description {
    margin: 0 0 1em;
  }

  label .selected {
    font-weight: 700;
  }

  .ethnicity-helpers {
    position: relative;
    padding-left: 30px;

    strong {
      display: block;
      margin: 0 0 0.5em;
    }

    .back {
      position: absolute;
      top: -25px;
      left: -10px;
      margin: 0;
      text-decoration: none;
      background-color: unset;
      border-bottom: none;

      &::before {
        background-color: unset;
      }
    }

    .back:before {
      position: absolute;
      border-style: solid;
      border-width: 0 0 2px 2px;
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      top: 50%;
      margin-top: -8px;
      left: 12px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      vertical-align: middle;
      color: $white-on-green;
    }
  }
}

// Address
.address-link {
  display: block;
  margin: 0 0 1.5em;

  @include mq('mobile') {
    margin: 0.5em 0 0.75em;
    padding: 12px 15px 22px;
  }
}

.address-link-show {
  padding: 12px 0 22px;
  margin: 0;
}

// Medical history
.medicalHistory {
  h2 {
    @include font-size(24);
    color: $white-on-green;
    text-transform: uppercase;
  }
}

.blood_transfusion_wrapper {
  > div {
    clear: both;
  }
}

.content--fail {
  h2 {
    line-height: 1.2em;
  }
}

.medical_conditions_wrapper {
  fieldset {
    margin: 0;
  }

  .medical-condition {
    padding: 10px;
    margin-bottom: 3px;
    background-color: $grey;

    .control--small {
      box-sizing: border-box;
      width: 100%;
    }

    .control--checkbox {
      margin: 0;
    }
  }

  .error-box {
    margin: 0 0 3px;

    .medical-condition {
      margin: 0;
      padding: 0;
      background: none;
    }

    .error-message {
      margin: 0;
    }
  }
}

.condition-questions {
  margin-top: 15px;

  .span_12_of_12 {
    clear: both;
  }

  @include mq('desktop') {
    .ongoing {
      float: right;

      div label:nth-child(2) {
        margin-right: 10px;
      }
    }
  }

  textarea {
    margin-bottom: 0;
  }
}

// Consent + GiftAid
.bordered,
.giftaid label {
  border: 1px solid $white-on-green;
  padding: 20px;
  width: 100%;
}

.giftaid input[type='checkbox'] {
  &:checked {
    background: #f9f9f9;
  }

  &:after {
    border-color: $white-on-green;
  }
}

// Donations
.amount-wrapper {
  border: 1px solid $grey;
  padding: 20px;
  margin-bottom: 20px;

  input.currency::-webkit-outer-spin-button,
  input.currency::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .float-label:focus-within label,
  .float-label .active-label {
    &:before {
      position: absolute;
      left: 12px;
      top: 20px;
      content: '£';
      @include font-size(24);
      color: $white-on-green;
    }

    &.error:before {
      color: #e36060;
    }
  }

  .float-label:focus-within input,
  .float-label .active-input {
    padding-left: 30px;
  }

  .giftaid {
    .label {
      text-align: center;
      margin: 1em 0;
    }

    label {
      box-sizing: border-box;
      width: 100%;
      background-image: url('../images/gift-aid.svg');
      background-size: 80px auto;
      background-repeat: no-repeat;
      background-position: right 20px top 10px;
      background-color: $white-on-green;
      color: $white;
      padding: 40px 10px;
      transition: 0.3s all ease-in-out;

      &:hover {
        background-color: $green-on-white;
      }

      @include mq('tablet') {
        background-size: 150px auto;
        background-position: right 20px center;
        padding: 20px 200px 20px 20px;
      }
    }

    .description {
      @include font-size(11);

      @include mq('tablet') {
        @include font-size(14);
      }
    }

    div.description {
      @include font-size(11);
    }

    input[type='checkbox']:checked + span {
      font-weight: normal;
    }
  }

  .giftaid-amount {
    text-align: center;
    font-weight: bold;
    margin-top: 0.5em;

    span {
      color: $white-on-green;
    }
  }
}

.contact_details_wrapper {
  h2 {
    @include font-size(24);
  }
}

.marketingPreferences {
  border: 1px solid #ddd;
  padding: 20px;

  h2 {
    @include font-size(24);
    color: $white-on-green;
    margin-top: 0;
  }

  .label {
    display: inline-block;
    display: inline-flex;
    align-items: center;
    margin: 0.5em 0;
  }

  .post {
    // Reverse checkbox for post opt-in
    input[type='checkbox']:checked {
      background-color: $light-grey;
      border: 1px solid $grey;

      &::after {
        display: none;
      }
    }

    input[type='checkbox']:not(:checked) {
      background: $white-on-green;

      &::after {
        display: block;
      }
    }

    input[type='checkbox']:checked + span {
      font-weight: normal;
    }

    input[type='checkbox']:not(:checked) + span {
      font-weight: 700;
    }
  }
}

// Payment form
.payment-icons {
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid $grey;
  margin-bottom: 20px;
  padding: 5px 10px;
  text-align: right;
  text-align: center;
  @include font-size(12);

  span,
  img {
    display: inline-block;
    vertical-align: middle;
    margin: 5px 0;
  }

  img {
    width: 63px;

    &:last-child {
      margin-left: 10px;
      width: 110px;
    }
  }
}

.StripeElement {
  background-color: $light-grey;
  border: 1px solid $grey;
  margin: 0.5em 0;
  padding: 15px;
}

.StripeElement--focus {
  border: 1px solid $white-on-green;
}

.payment-error .StripeElement,
.StripeElement--invalid {
  background-color: #fae2e2;
  border: 1px solid #e36060;
}

.error-box {
  margin: 10px 0;
  padding: 10px 20px;
  background-color: rgba(250, 226, 226, 0.5); // #fae2e2 with 50% opacity;

  h2 {
    margin-top: 0.5em;
  }

  ul,
  li {
    margin: 0;
  }
}

.success-box {
  padding: 10px;
  border: 1px solid lighten($white-on-green, 40%);
  background-color: lighten($white-on-green, 60%);
}

// Landing page and Support pages
.page {
  .btn {
    text-transform: none;
    line-height: 1.2;

    //@TODO below modified from btn--large - consolidate into a new button style
    @include font-size(22);

    @include mq('mobile') {
      min-height: 64px;
      font-size: 26px;
      padding: 20px;
      min-height: 105px;
      margin: 1em 0;
    }

    &.right-arrow {
      padding-right: 35px;

      @include mq('mobile') {
        padding-right: 70px;
      }
    }

    &.right-arrow::before {
      @include mq('mobile') {
        width: 50px;
        height: 50px;
        margin-top: -25px;
        right: 30px;
      }
    }
  }
}

.landing-page {
  color: $white;

  h1 {
    @include font-size(32);
    font-weight: 700;
    max-width: 80%;
    margin: 0.5em 0;

    @include mq('sm-mobile') {
      margin: 1em 0;
    }

    @include mq('mobile') {
      max-width: 60%;
    }

    @include mq('tablet') {
      @include font-size(50);
      max-width: 650px;
    }
  }

  h2 {
    @include font-size(22);
    font-weight: 700;
    margin: 0 0 1em;
    opacity: 0.7;
    max-width: 80%;

    @include mq('mobile') {
      max-width: 60%;
    }

    @include mq('tablet') {
      @include font-size(36);
      max-width: 650px;
    }
  }
}

.support-page {
  h1 {
    color: $white;
    @include font-size(26);
    font-weight: 700;
    max-width: 60%;
    margin: 0.5em 0;

    @include mq('mobile') {
      @include font-size(50);
      max-width: 550px;
    }
  }

  h2 {
    @include font-size(24);

    @include mq('mobile') {
      @include font-size(36);
    }
  }

  .bg-img {
    min-height: 0;
    height: 300px;

    @include mq('mobile') {
      height: 450px;
    }
  }

  .wrapper {
    background: $white;

    section {
      max-width: 850px;
    }
  }

  .info-question {
    position: relative;
    @include font-size(20);
    font-weight: 300;
    text-decoration: none;
    display: block;
    border-bottom: 1px dashed #999;
    padding: 0.5em 0;
    padding-right: 40px;
    margin: 0 0 1em;

    @include mq('mobile') {
      @include font-size(24);
    }

    &:before {
      position: absolute;
      border-style: solid;
      border-width: 0 2px 2px 0;
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      top: 50%;
      margin-top: -10px;
      right: 15px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      vertical-align: middle;
      transition: all 0.4s;
    }
  }
}

// Footer
footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
  background-color: $black;
  color: $mid-grey;

  div {
    margin: 0;
    position: relative;
    text-align: center;
    @include font-size(12);

    @include mq('desktop') {
      text-align: right;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1;

  .popup {
    position: relative;
    width: 100%;
    max-width: 500px;
    background: $white;
    padding: 30px;
    border: 1px solid $grey;
    text-align: center;
    box-shadow: 0 0 20px $mid-grey;
  }
}

.ReactModal__Overlay {
  z-index: 1;
}

.close {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  height: 20px;
  opacity: 0.5;
  cursor: pointer;
}

.close:hover {
  opacity: 1;
}

.close:before,
.close:after {
  position: absolute;
  left: 10px;
  content: ' ';
  height: 20px;
  width: 2px;
  background-color: #333;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.description--small {
  margin-bottom: 20px;
  margin-top: 0;
  font-size: 13px;
}

.fieldset--no-margin {
  margin: 0 !important;
}

.payment-method-wrapper {
  border: 1px solid $grey;
  margin-top: 20px;
  padding: 20px;

  h3 {
    margin-top: -30px;
    text-align: center;
  }

  span {
    background-color: $white;
    display: inline-block;
    margin: 0 auto;
    padding: 0 20px;
  }
}

.separator {
  border-top: 1px solid $grey;
  color: $mid-grey;
  font-weight: normal;
  margin: 60px 0 40px;
  text-align: center;

  span {
    background-color: white;
    display: inline-block;
    padding: 0 20px;
    transform: translateY(-10px);
  }
}
