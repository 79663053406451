/**
 * Button styles for calls to actions and forms
 */

// Variables
$white: #f7f6f0;
$black: #1c1c1c;
$green: #c0cb02;
$green-light: #e8ebb3;
$green-lighter: #f2f4dc;
$green-dark: #acb602;
$green-darker: #6f8300;

// Functions
@function encodeColor($string) {
  @if type-of($string) == 'color' and str-index(#{$string}, '#') == 1 {
    $hex: str-slice(ie-hex-str($string), 4);
    $string: unquote('#{$hex}');
    @return '%23' + $string;
  }
  @return $string;
}

@function rotateArrow($string) {
  @if $string == 'left' {
    @return 180deg;
  }
  @if $string == 'up' {
    @return 270deg;
  }
  @if $string == 'down' {
    @return 90deg;
  }
  @return 0deg;
}

// Mixins
@mixin chevron($color, $position, $size, $height, $width) {
  background-image: url("data:image/svg+xml,%3Csvg width='19px' height='22px' viewBox='0 0 19 22' fill='#{encodeColor($color)}' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg %3E%3Cpolygon id='Path' points='0 0 8 0 19 11 8 22 0 22 11 11'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
  background-position: left center;
  background-size: $size;
  background-repeat: no-repeat;
  content: '';
  display: inline-block;
  width: $width;
  height: $height;
  transform: rotate(rotateArrow($position));
  transform-origin: center center;
  margin-left: auto;
}

button,
.btn {
  border: none;
  cursor: pointer;
  outline: none;
  height: 70px;
  padding: 0 0.75rem;
}

.btn {
  background-color: $green;
  border: none;
  border-radius: 0 !important;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  font-size: 1rem;
  font-weight: bold;
  color: $black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 8px solid $green-darker;
  height: 70px;
  outline: none;
  padding: 0 0.75rem;
  text-decoration: none;
  transition: opacity 0.3s, filter 0.3s;
  &:not(.left-arrow):before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    height: 100%;
    width: 0;
    background-color: $green-dark;
    transition: 0.3s width;
    top: 0;
  }
  &:hover {
    color: $black;
    &::before {
      width: 100%;
    }
  }
  &:active {
    color: $black;
    border-bottom-width: 4px;
    transform: translateY(2px);
  }
  &:visited {
    color: $black;
  }
}

.btn--primary {
  background-color: $green;
  border-color: $green-darker;
  border-top: 2px solid $green;
  &:not(.left-arrow)::before {
    background-color: $green-dark;
  }
  &:hover {
    color: $black;
  }
}

.btn--secondary,
.btn--tertiary {
  background-color: $white;
  border: 2px solid $black;
  border-bottom: 8px solid $black;
  &:not(.left-arrow)::before {
    background-color: $black;
  }
  &:hover {
    color: $white;
  }
}

.right-arrow {
  &::after {
    @include chevron($black, right, 100%, 23px, 20px);
    margin-left: 15px;
    vertical-align: middle;
  }
}

.left-arrow {
  &::before {
    @include chevron($black, left, 100%, 23px, 20px);
    vertical-align: middle;
    margin-right: 15px;
  }
  &:hover {
    background-color: $black;
    &::before {
      @include chevron($white, left, 100%, 23px, 20px);
      vertical-align: middle;
    }
  }
}

.btn--secondary.right-arrow,
.btn--tertiary.right-arrow {
  &:hover {
    &::after {
      @include chevron($white, right, 100%, 23px, 20px);
      margin-left: 5px;
    }
  }
}

.btn--large {
  padding: 10px 15px;
  @include mq('mobile') {
    font-size: 3vw;
    padding: 25px;
    min-height: 105px;
    margin: 1em 0;
  }
  @include mq('desktop') {
    font-size: 32px;
  }
  &.right-arrow::after {
    @include chevron($black, right, 100%, 55px, 50px);
  }
  &.left-arrow::before {
    @include chevron($black, left, 100%, 55px, 50px);
  }
  &.left-arrow:hover::before {
    @include chevron($white, left, 100%, 55px, 50px);
  }
}

.btn--medium {
  min-height: 48px;
  margin: 10px 0;
  padding: 10px 15px;
  @include font-size(18);
  &.right-arrow {
    padding: 10px 15px 10px 15px;
  }
  &.left-arrow {
    padding: 10px 15px 10px 15px;
  }
}

.btn--small {
  min-height: 36px;
  margin: 10px 0;
  padding: 10px 15px;
  @include font-size(13);
  text-transform: none;
}

.btn--inline {
  display: inline-block;
  margin-right: 10px;
}
